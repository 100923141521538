import { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import styled from "@emotion/styled";

import { useEditFavlist, useReportList, useResearchSingleFavlist } from "hooks/api/analytics";
import { ContextSettings } from "context/context-settings";
import { CusTypo } from "components/cusTypo/custom-typography";

import { MTSModal } from "components/mts-modal/mts-modal";
import { PopupAction } from "components/popup-action/popup-action";
import { EditFavForm } from "../tableFav/editForm";
import { DeleteFavForm } from "../tableFav/deleteForm";
import { makeSet } from "./utils";
import { LoadFavForm } from "../tableFav/loadForm";
import { Toast } from "components/toast/toast";
import { CustomWidthTooltip } from "components/tooltip/tooltip";

export const STextCut = styled(CusTypo)`
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: inherit;
  text-overflow: ellipsis;
`;

export const SGroup = styled.div`
  min-width: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  & > div {
    margin-left: auto;
    min-width: 0;
    flex: 0 0 auto;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 8px 32px;
  }
`;

export const SHeadStyle = styled.div`
  & .th {
    background-color: white !important;
  }
  & .thead {
    background-color: white !important;
  }
`;

interface IProp {
  id: string;
  author: string;
  title: string;
  setFavs: Function;
  closeSlide: Function;
  comment?: string;
}
export const SelectAction: React.FC<IProp> = ({
  id,
  author,
  title,
  comment,
  setFavs,
  closeSlide,
}) => {
  const { profile } = useContext(ContextSettings);
  const [popset, setPop] = useState<any>([]);
  const [isClicked, setClicked] = useState(false);
  const isCanEdit = profile?.id === +author;

  const [isOpen, setOpen] = useState(false);
  const [action, setAction] = useState<string | undefined>(undefined);

  const { data, isLoading } = useResearchSingleFavlist(id, isClicked);
  const { mutate: duplicate } = useEditFavlist();
  const { mutate: report, isLoading: isRepLoading } = useReportList();

  useEffect(() => {
    setPop(
      makeSet({
        load: () => {
          setOpen(true);
          setAction("load");
        },
        edit: () => {
          setOpen(true);
          setAction("edit");
        },
        copy: () =>
          duplicate({
            id_list: isCanEdit ? null : +id,
            comment_: "",
            name_list: title,
            id_pp_list: data ?? [],
          }),
        del: () => {
          setOpen(true);
          setAction("delete");
        },
        xls: () =>
          report(id, {
            onSuccess: () => {
              toastRepSucc();
            },
            onError: () => {
              toastRepFail();
            },
          }),
        flag: isCanEdit,
      }),
    );
  }, [data, duplicate, id, isCanEdit, title, report]);

  useEffect(() => {
    if (isRepLoading) toastRepPending();
  }, [isRepLoading]);

  return (
    <div className="popup-container">
      <PopupAction
        view="light"
        items={popset}
        isLoading={isLoading}
        onOpen={() => setClicked(true)}
      />
      <MTSModal open={isOpen} close={() => setOpen(false)} size="S">
        {action === "edit" ? (
          <EditFavForm
            id={+id}
            onCancel={() => setOpen(false)}
            idList={data ?? []}
            title={title}
            comment={comment}
          />
        ) : action === "delete" ? (
          <DeleteFavForm onCancel={() => setOpen(false)} id={id} />
        ) : action === "load" ? (
          <LoadFavForm
            onCancel={() => {
              closeSlide();
              setOpen(false);
            }}
            handleClick={() => setFavs({ title, id, comment, author, favs: data ?? [] })}
          />
        ) : null}
      </MTSModal>
    </div>
  );
};
export const NameLoadListForm: React.FC<IProp> = ({
  id,
  author,
  title,
  comment,
  setFavs,
  closeSlide,
}) => {
  const [isClicked, setClicked] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const { data } = useResearchSingleFavlist(id, isClicked);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    setOpen(true);
    setClicked(true);
    e.stopPropagation();
  };

  return (
    <>
      {title.length > 14 ? (
        <CustomWidthTooltip title={title} arrow placement="right">
          <span
            style={{
              width: "100%",
              overflowX: "hidden",
              overflowY: "inherit",
              textOverflow: "ellipsis",
            }}
          >
            <STextCut variant="c1_regular" onClick={handleClick} styles={{ cursor: "pointer" }}>
              {title ?? ""}
            </STextCut>
          </span>
        </CustomWidthTooltip>
      ) : (
        <STextCut variant="c1_regular" onClick={handleClick} styles={{ cursor: "pointer" }}>
          {title ?? ""}
        </STextCut>
      )}
      <MTSModal open={isOpen} close={() => setOpen(false)} size="S">
        <LoadFavForm
          onCancel={() => {
            closeSlide();
            setOpen(false);
          }}
          handleClick={() => setFavs({ title, id, comment, author, favs: data ?? [] })}
        />
      </MTSModal>
    </>
  );
};

export const toastRepPending = () =>
  toast(<Toast title="Список загружается" isLoading />, {
    progress: undefined,
    autoClose: 5000,
    hideProgressBar: true,
  });

export const toastRepSucc = () =>
  toast(<Toast title="Список загружен" />, {
    progress: undefined,
    autoClose: 2000,
    hideProgressBar: true,
  });

export const toastRepFail = () =>
  toast(<Toast title="Не удалось загрузить" isError />, {
    autoClose: 2000,
    hideProgressBar: false,
  });

export const toastEnd = () =>
  toast(<Toast title="Загружен весь список устройств" />, {
    autoClose: 3000,
    hideProgressBar: false,
  });
