/* eslint-disable no-param-reassign */
import { Dispatch, SetStateAction, useLayoutEffect, useRef } from "react";
import styled from "@emotion/styled";
import { NumericFormat } from "react-number-format";
import { rangeToColor } from "theme";
import theme from "theme";

import Icon from "components/icons";
import { IFavs } from "pages/analytics/const";
import { CusTypo } from "components/cusTypo/custom-typography";
import { EMPTY_CELL_VALUE } from "stream-constants/keys-and-constants";

interface IMarkerValueProp {
  value: string;
  withVal?: boolean;
}

export const SCenter = styled.div`
  min-height: 0;
  min-width: 0;
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
`;

const SRange = styled.div<{ range: string }>`
  padding: 4px 12px;
  color: ${(props) => rangeToColor[props.range]};
  background-color: ${(props) => rangeToColor[`${props.range}Bg`]};
  border-radius: 16px;
`;

export const RageVal = (value) => {
  const rangeValues = value?.split(";");
  return <SRange range={rangeValues?.[1] ?? ""}>{rangeValues?.[0] ?? EMPTY_CELL_VALUE}</SRange>;
};

export const ColorCircle = styled.div<{ color: string }>`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: ${(props) => rangeToColor[`${props.color}`]};
`;

export const SToolWrap = styled.div`
  max-width: 319px;
`;

export const SHeadGroup = styled.div<{ pos?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px !important;
  text-align: center !important;
  background: black;
  ${(props) => props.pos && `  border-top-${props.pos}-radius: 8px;`};

  & > span {
    color: white;
  }
`;

export const SOverflow = styled.div`
  max-width: 100%;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const MarkerValue: React.FC<IMarkerValueProp> = ({ value, withVal = true }) => {
  const rangeValues = value?.split(";");
  return rangeValues?.[0] && +rangeValues?.[0] !== 0 ? (
    <>
      <ColorCircle color={rangeValues?.[1]} />
      {withVal ? rangeValues?.[0] : null}
    </>
  ) : null;
};

export const SStar = styled.div<{ withValue?: boolean }>`
  cursor: pointer;

  ${(props) =>
    props.withValue &&
    `
		& > .MuiSvgIcon-root path {
			stroke: ${props.theme.mtsColor.background.inverted.lightMode};
			fill: ${props.theme.mtsColor.background.inverted.lightMode};
		}
  `};
`;

const SDiv = styled.div<{ isSelected?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;

  &:before {
    display: none;
    position: absolute;
    content: "";
    left: -8px;
    width: 3px;
    height: 100%;
    background: ${(props) => props.theme.mtsColor.red};
  }
  ${(props) =>
    props.isSelected &&
    `
		&:before {
			display: block;
		}
  `}
`;

export const SBlue = styled.div`
  color: ${(props) => props.theme.mtsColor.text.primaryLink.lightMode};
`;

const SGrey = styled(CusTypo)`
  color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
`;

export const Highlighter = ({ val, isSelected }) => {
  const refElem = useRef<any>(null);

  useLayoutEffect(() => {
    if (isSelected && refElem.current) {
      refElem.current.closest(".tbody_tr").style.background =
        theme.mtsColor.background.secondary.lightMode;
      if (refElem.current.closest("[data-sticky-td]"))
        refElem.current.closest("[data-sticky-td]").style.background =
          theme.mtsColor.background.secondary.lightMode;
    }
  }, [isSelected]);
  return (
    <SDiv isSelected={isSelected} ref={refElem}>
      <CusTypo variant="c1_bold">{val}</CusTypo>
    </SDiv>
  );
};

interface IProps {
  id: number;
  favs: number[];
  setFavs: Dispatch<SetStateAction<IFavs>>;
}

export const Favorite: React.FC<IProps> = ({ id, setFavs, favs }) => {
  const toggle = (e) => {
    e.stopPropagation();
    setFavs((s) => {
      const cur = s.favs;
      if (cur?.includes(id)) {
        const removed = cur?.filter((a) => a !== id);
        return { ...s, favs: removed };
      } else {
        return { ...s, favs: [...cur, id] };
      }
    });
  };

  return (
    <SStar onClick={toggle} withValue={favs?.includes(id)}>
      <Icon.Star />
    </SStar>
  );
};

export const OffsetAmount = ({ data }) => {
  return (
    <SGrey variant="c1_regular" font="comp">
      <NumericFormat displayType="text" value={data?.offset ?? 0} thousandSeparator={" "} />
    </SGrey>
  );
};
