import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const GeoPositionMts = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_4958_33083)">
        <path
          d="M9.52098 9.42285C9.57507 8.69599 9.60212 8.33256 9.96691 7.96778C10.3317 7.60299 10.6951 7.57595 11.422 7.52185C11.6104 7.50783 11.8048 7.49933 11.9991 7.49933C12.1935 7.49933 12.3879 7.50783 12.5763 7.52185C13.3031 7.57595 13.6666 7.60299 14.0313 7.96778C14.3961 8.33256 14.4232 8.69599 14.4773 9.42285C14.4913 9.61127 14.4998 9.80564 14.4998 10C14.4998 10.1944 14.4913 10.3887 14.4773 10.5771C14.4232 11.304 14.3961 11.6674 14.0313 12.0322C13.6666 12.397 13.3031 12.4241 12.5763 12.4782C12.3879 12.4922 12.1935 12.5007 11.9991 12.5007C11.8048 12.5007 11.6104 12.4922 11.422 12.4782C10.6951 12.4241 10.3317 12.397 9.96691 12.0322C9.60212 11.6674 9.57507 11.304 9.52098 10.5771C9.50695 10.3887 9.49846 10.1944 9.49846 10C9.49846 9.80564 9.50695 9.61127 9.52098 9.42285Z"
          fill="#969FA8"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.4605 21.0134C11.1739 21.6703 11.5306 21.9987 11.999 21.9987C12.4673 21.9987 12.824 21.6703 13.5374 21.0134C16.7291 18.0744 19.8693 14.9096 19.995 10.2469C20.011 9.65421 19.9605 9.06295 19.9119 8.47281C19.6316 5.06375 17.0169 2.33906 13.6071 2.06674C13.0816 2.02477 12.5416 2 11.999 2C11.4564 2 10.9165 2.02477 10.3909 2.06674C6.98119 2.33906 4.36649 5.06375 4.08615 8.47281C4.03755 9.06295 3.98703 9.65421 4.00301 10.2469C4.12868 14.9095 7.2689 18.0743 10.4605 21.0134ZM11.999 19.7109C9.20103 17.1498 6.11207 14.2662 6.00228 10.1931C5.98828 9.67336 6.03687 9.15409 6.07942 8.63673C6.28314 6.15934 8.16216 4.25111 10.5501 4.06039C11.0302 4.02205 11.5166 4 11.999 4C12.4814 4 12.9678 4.02205 13.4479 4.06039C15.8359 4.25111 17.7149 6.15934 17.9186 8.63673C17.9612 9.15408 18.0097 9.67335 17.9957 10.1931C17.886 14.2662 14.797 17.1498 11.999 19.7109Z"
          fill="#969FA8"
        />
      </g>
      <defs>
        <clipPath id="clip0_4958_33083">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default GeoPositionMts;
