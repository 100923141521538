import { makeStyles } from "@mui/styles";
import styled from "@emotion/styled";
import { Theme } from "@mui/material";
import { useTheme } from "@mui/styles";

import Icon from "components/icons";
import { OFFSET_24 } from "theme";
import { CustomWidthTooltip } from "components/tooltip/tooltip";
import { CusTypo } from "components/cusTypo/custom-typography";

const useStyles = makeStyles((theme: Theme) => ({
  sortIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  sorting: {
    position: "relative",
    width: "min-content",
    zIndex: "1",
    overflow: "initial !important",
    "&:before": {
      position: "absolute",
      content: "''",
      width: "calc(100% + 12px)",
      right: "-4px",
      top: "1px",
      height: "100%",
      backgroundColor: theme.mtsColor.controls.inactive.lightMode,
      borderRadius: "8px",
      zIndex: "-1",
    },
  },
  sortingLeft: {
    position: "relative",
    width: "min-content",
    zIndex: "1",
    overflow: "initial !important",
    "&:before": {
      position: "absolute",
      content: "''",
      width: "calc(100% + 6px)",
      left: 0,
      top: "1px",
      height: "100%",
      backgroundColor: theme.mtsColor.controls.inactive.lightMode,
      borderRadius: "8px",
      zIndex: "-1",
    },
  },
}));

interface IRenderFilterProps {
  isSorted: boolean;
  isSortedDesc: boolean;
  title: string | React.ReactNode;
  withPadding?: boolean;
  tooltip?: any;
  showHints?: boolean;
  isSortIconLeft?: boolean;
}

export const SWrap = styled.div`
  display: flex;
`;

export const RenderFilter = ({
  isSorted,
  isSortedDesc,
  title,
  withPadding,
  tooltip,
  showHints,
  isSortIconLeft,
}: IRenderFilterProps) => {
  const theme = useTheme<Theme>();
  const classes = useStyles();

  return (
    <div
      style={{
        display: "flex",
        height: 40,
        alignItems: "center",
        overflow: isSortIconLeft ? "hidden" : "initial",
        padding: isSortIconLeft ? "0 6px 0 0 " : "0",
      }}
    >
      <CusTypo
        variant="c1_bold"
        styles={{
          color: theme.mtsColor.text.secondary.lightMode,
          whiteSpace: "nowrap",
          display: "flex",
          ...(withPadding ? { margin: `0 0 0 ${OFFSET_24}` } : {}),
        }}
        className={isSorted ? (isSortIconLeft ? classes.sortingLeft : classes.sorting) : ""}
      >
        {tooltip && showHints ? (
          <HeaderItemTooltip
            isSorted={isSorted}
            isSortedDesc={isSortedDesc}
            title={title}
            tooltip={tooltip}
            isSortIconLeft={isSortIconLeft}
          />
        ) : (
          <HeaderItem
            isSorted={isSorted}
            isSortedDesc={isSortedDesc}
            title={title}
            isSortIconLeft={isSortIconLeft}
          />
        )}
      </CusTypo>
    </div>
  );
};

export const HeaderItem = ({
  isSorted,
  isSortedDesc,
  title,
  isSortIconLeft,
}: IRenderFilterProps) => {
  const classes = useStyles();
  const theme = useTheme<Theme>();

  return (
    <>
      {isSortIconLeft ? null : title}
      <div className={classes.sortIcon}>
        {isSorted ? (
          isSortedDesc ? (
            <Icon.ArrowDownMin
              sx={{
                fontSize: 16,
                color: theme.mtsColor.icons.primary.lightMode,
              }}
            />
          ) : (
            <Icon.ArrowUpMin
              sx={{
                fontSize: 16,
                color: theme.mtsColor.icons.primary.lightMode,
              }}
            />
          )
        ) : (
          <Icon.ArrowDownMin
            sx={{
              fontSize: 16,
              color: theme.mtsColor.text.secondary.lightMode,
            }}
          />
        )}
      </div>

      {isSortIconLeft ? title : null}
    </>
  );
};

const HeaderItemTooltip = ({
  isSorted,
  isSortedDesc,
  title,
  tooltip,
  isSortIconLeft,
}: IRenderFilterProps) => {
  const classes = useStyles();
  const theme = useTheme<Theme>();

  return (
    <CustomWidthTooltip title={tooltip} arrow placement="right">
      <SWrap>
        {isSortIconLeft ? null : title}
        <div className={classes.sortIcon}>
          {isSorted ? (
            isSortedDesc ? (
              <Icon.ArrowDownMin
                sx={{
                  fontSize: 16,
                  color: theme.mtsColor.icons.primary.lightMode,
                }}
              />
            ) : (
              <Icon.ArrowUpMin
                sx={{
                  fontSize: 16,
                  color: theme.mtsColor.icons.primary.lightMode,
                }}
              />
            )
          ) : (
            <Icon.ArrowDownMin
              sx={{
                fontSize: 16,
                color: theme.mtsColor.text.secondary.lightMode,
              }}
            />
          )}
        </div>
        {isSortIconLeft ? title : null}
      </SWrap>
    </CustomWidthTooltip>
  );
};
