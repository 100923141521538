import styled from "@emotion/styled";
import { Select } from "components/select/select";

export const STop = styled.div`
  position: relative;
  top: -6px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
`;

export const SMarksForm = styled.form`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 16px;
  padding: 0 16px;

  & ${Select} {
    flex: 0 0 138px;
    min-width: 138px;
  }
`;

export const SOptCont = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 208px;
  padding: 24px;
  cursor: default;

  & button {
    white-space: nowrap;
  }
`;

export const SSpec = styled.div`
  position: relative;
  min-height: 0;
  min-width: 0;
  margin: 0 0 0 16px;
  flex: 0 0 auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 8px;

  &:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 1px;
    background: ${(props) => props.theme.mtsColor.background.stroke.lightMode};
    left: -16px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const SControls = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 12px;
`;

export const SBtnMap = styled.div`
  background-color: ${(props) => props.theme.mtsColor.controls.tertiaryActive.lightMode};
  border: 1px solid ${(props) => props.theme.mtsColor.background.stroke.lightMode};
  border-radius: 6px;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
