import React from "react";
import theme from "theme";

import { calcFooterNonNull } from "./utils";
import { SToolWrap, OffsetAmount } from "./subComponents";
import { CusTypo } from "components/cusTypo/custom-typography";

export const headersInfo = {
  id_pp: {
    title: "ID",
    tooltipText: "Уникальный идентификатор прибора учета в системе АСКУЭ",
  },
  sn: {
    title: "Серийный №",
    tooltipText: "Серийный № прибора учета",
  },
  fl_ul: {
    title: "ФЛ/ЮЛ",
    tooltipText: "Тип абонента",
  },
  name_res: {
    title: "РЭС",
    tooltipText: "Отношение абонента к районному подразделению сетевой организации",
  },
  fp: {
    title: "Фрод",
    tooltipText: "Статус абонента определённый по результатам анализа характера потребления",
  },
  fp_dc: {
    title: "Дата смены",
    tooltipText: "Дата смены статуса",
  },
  fp_uc: {
    title: "Кто сменил",
    tooltipText: "Кто сменил",
  },
  fp_cm: {
    title: "Комментарий",
    tooltipText: "Комментарий",
  },
  t0: {
    title: "T0", // Плохая телеметрия
    tooltipText: "Качество телеметри прибора",
  },
  t1: {
    title: "T1", // Смена ПУ
    tooltipText: "Смена ПУ",
  },
  t2: {
    title: "T2", // Майнеры
    tooltipText: "Тикет Майнеры",
  },
  t1_dc: {
    title: "Дата смены ПУ",
    tooltipText: "Дата смены ПУ",
  },
  t2_kc: {
    title: "Коэф.к",
    tooltipText: "Коэффициент корреляции",
  },
  t3: {
    title: "T3", // Снижение потребления
    tooltipText: "Тикет снижение потребления",
  },
  t4: {
    title: "T4", // Рецидив
    tooltipText: "Рецидив, подозрение на повторный фронт",
  },
  t5: {
    title: "T5", // Повторная проверка
    tooltipText: "Повторная проверка",
  },
  d_winter: {
    title: "Дн.зима",
    tooltipText:
      "Среднее дневное зимнее потребление абонента в отчетный период статистической модели потребления",
  },
  d_summer: {
    title: "Дн.лето",
    tooltipText:
      "Среднее дневное летнее потребление абонента в отчетный период статистической модели потребления",
  },
  n_summer: {
    title: "Нч.лето",
    tooltipText:
      "Среднее ночное летнее потребление абонента в отчетный период статистической модели потребления",
  },
  koef_dn: {
    title: "Коэф.д/н",
    tooltipText:
      "Соотношение дневного и ночного потребления абонента в отчетный период статистической модели",
  },
  amplitude: {
    title: "Размах",
    tooltipText:
      "Размах значений дневного потребления в абсолютных величинах статистической модели",
  },
  multiplicity: {
    title: "Кратность",
    tooltipText: "Кратность зимнего и летнего потребления",
  },
  cnt_04: {
    title: "Кол.>0.4",
    tooltipText: "Кол.>0.4",
  },
  cnt_008: {
    title: "Кол.>0.08",
    tooltipText: "Кол.>0.08",
  },
  consumption: {
    title: "Тип потреб.",
    tooltipText: "Тип потребления",
  },
  score: {
    title: "Скор",
    tooltipText: "Сборный коэффициент основных параметров потребления",
  },
  district: {
    title: "Район",
    tooltipText: "Район",
  },
  tol: {
    title: "Тип",
    tooltipText: "Тип поселения",
  },
  locality: {
    title: "Насел. пункт",
    tooltipText: "Населённый пункт",
  },
  street: {
    title: "Улица",
    tooltipText: "Улица",
  },
  house: {
    title: "Дом",
    tooltipText: "Дом",
  },
  apartment: {
    title: "Кв",
    tooltipText: "Квартира",
  },
  type1: {
    title: "Type1",
    tooltipText:
      "'Смена прибора' сигнализирует о значительном снижении потребления после смены прибора",
  },
  type2: {
    title: "Type2",
    tooltipText: '"Майнеры" абоненты с постоянным/круглосуточным завышенным потреблением',
  },
  type3: {
    title: "Type3",
    tooltipText:
      '"Снижение" фиксирует  пропорциональное снижение характеристик (более 2-х характеристик) на 50 и более %',
  },
  type4: {
    title: "Type4",
    tooltipText: "Type4",
  },
  type5: {
    title: "Type5",
    tooltipText: "Type5",
  },
  unitype: {
    title: "Unitype",
    tooltipText:
      "Универсальный скоринговый балл результатов работы модели по поиску взломанных приборов учета",
  },
  dt_send: {
    title: "Дата отчета",
    tooltipText: "Дата отчета",
  },
  dt_get: {
    title: "Дата выезда",
    tooltipText: "Дата выезда",
  },
  phase_a_cl: {
    title: "Клещи (А)",
    tooltipText: "Клещи (А)",
  },
  phase_a_pu: {
    title: "ПУ (А)",
    tooltipText: "ПУ (А)",
  },
  phase_b_cl: {
    title: "Клещи (В)",
    tooltipText: "Клещи (В)",
  },
  phase_b_pu: {
    title: "ПУ (В)",
    tooltipText: "ПУ (В)",
  },
  phase_c_cl: {
    title: "Клещи (С)",
    tooltipText: "Клещи (С)",
  },
  phase_c_pu: {
    title: "ПУ (С)",
    tooltipText: "ПУ (С)",
  },
  result_verify: {
    title: "Статус проверки",
    tooltipText: "Статус проверки",
  },
  comment_verify: {
    title: "Комментарий",
    tooltipText: "Комментарий",
  },
  dt_change: {
    title: "Дата смены",
    tooltipText: "Дата смены",
  },
  sn_change: {
    title: "Новый S/N",
    tooltipText: "Новый S/N",
  },
  type_hack: {
    title: "Тип взлома",
    tooltipText: "Тип взлома",
  },
};

export const groupHeaderByName = {
  abonent: "Абонент",
  status: "Статус фрода",
  tickets: "Тикеты",
  consumption: "Потребление электроэнергии",
  address: "Адрес абонента",
  bigdata: "Bigdata",
  feedback: "Обратная связь",
};

export const renderTooltip = (id: string) => {
  return (
    <SToolWrap>
      <CusTypo
        variant="p4_bold"
        styles={{
          color: theme.mtsColor.text.inverted.lightMode,
          width: "100%",
          display: "block",
        }}
      >
        {headersInfo[id]?.title ?? ""}
      </CusTypo>
      <CusTypo
        variant="p4_regular"
        styles={{
          color: "#CECECF",
          width: "100%",
        }}
      >
        {headersInfo[id]?.tooltipText ?? ""}
      </CusTypo>
    </SToolWrap>
  );
};

export const tableStructure = [
  {
    accessor: "id",
    minWidth: 0,
    maxWidth: 0,
  },
  {
    sticky: "left",
    id: "id_pp",
    Header: null,
    Footer: (table) => calcFooterNonNull(table, "id_pp"),
    columns: [
      {
        accessor: "id_pp",
        minWidth: 68,
        width: 68,
        sticky: "left",
        Footer: (table) => <OffsetAmount data={table.globalData} />,
      },
    ],
  },
  {
    id: "abonent",
    headerTitle: "abonent",
    Footer: () => null,
    columns: [
      {
        accessor: "sn",
        minWidth: 140,
        width: 140,
      },
      {
        accessor: "fl_ul",
        minWidth: 75,
        width: 75,
      },
      {
        accessor: "name_res",
        minWidth: 120,
        width: 120,
      },
    ],
  },
  {
    id: "status",
    headerTitle: "status",
    Footer: () => null,
    columns: [
      {
        accessor: "fp",
        minWidth: 90,
        width: 90,
      },
      {
        accessor: "fp_dc",
        minWidth: 100,
        width: 100,
        sortType: "datetime",
      },
      {
        accessor: "fp_uc",
        minWidth: 95,
        width: 95,
      },
      {
        accessor: "fp_cm",
        minWidth: 160,
        width: 160,
      },
    ],
  },
  {
    id: "tickets",
    headerTitle: "tickets",
    Footer: () => null,
    columns: [
      {
        accessor: "t0",
        minWidth: 42,
        width: 42,
        Footer: (table) => calcFooterNonNull(table, "t0"),
      },
      {
        accessor: "t1",
        minWidth: 42,
        width: 42,
        Footer: (table) => calcFooterNonNull(table, "t1"),
      },
      {
        accessor: "t1_dc",
        minWidth: 120,
        width: 120,
      },
      {
        accessor: "t2",
        minWidth: 42,
        width: 42,
        Footer: (table) => calcFooterNonNull(table, "t2"),
      },
      {
        accessor: "t2_kc",
        minWidth: 55,
        width: 55,
      },
      {
        accessor: "t3",
        minWidth: 42,
        width: 42,
        Footer: (table) => calcFooterNonNull(table, "t3"),
      },
      {
        accessor: "t4",
        minWidth: 42,
        width: 42,
        Footer: (table) => calcFooterNonNull(table, "t4"),
      },
      {
        accessor: "t5",
        minWidth: 42,
        width: 42,
        Footer: (table) => calcFooterNonNull(table, "t5"),
      },
    ],
  },
  {
    id: "consumption",
    headerTitle: "consumption",
    Footer: () => null,
    columns: [
      {
        accessor: "d_winter",
        minWidth: 65,
        width: 65,
      },
      {
        accessor: "d_summer",
        minWidth: 65,
        width: 65,
      },
      {
        accessor: "n_summer",
        minWidth: 65,
        width: 65,
      },
      {
        accessor: "koef_dn",
        minWidth: 65,
        width: 65,
      },
      {
        accessor: "amplitude",
        minWidth: 65,
        width: 65,
      },
      {
        accessor: "multiplicity",
        minWidth: 65,
        width: 65,
      },
      {
        accessor: "cnt_04",
        minWidth: 65,
        width: 65,
      },
      {
        accessor: "cnt_008",
        minWidth: 65,
        width: 65,
      },
      {
        accessor: "consumption",
        minWidth: 65,
        width: 65,
      },
      {
        accessor: "score",
        minWidth: 64,
        width: 64,
      },
    ],
  },
  {
    id: "address",
    headerTitle: "address",
    Footer: () => null,
    columns: [
      {
        accessor: "district",
        minWidth: 96,
        width: 96,
      },
      {
        accessor: "tol",
        minWidth: 54,
        width: 54,
      },
      {
        accessor: "locality",
        minWidth: 146,
        width: 146,
      },
      {
        accessor: "street",
        minWidth: 95,
        width: 95,
      },
      {
        accessor: "house",
        minWidth: 54,
        width: 54,
      },
      {
        accessor: "apartment",
        minWidth: 54,
        width: 54,
      },
    ],
  },
  {
    id: "bigdata",
    headerTitle: "bigdata",
    Footer: () => null,
    columns: [
      {
        accessor: "type1",
        minWidth: 64,
        width: 64,
      },
      {
        accessor: "type2",
        minWidth: 64,
        width: 64,
      },
      {
        accessor: "type3",
        minWidth: 64,
        width: 64,
      },
      {
        accessor: "type4",
        minWidth: 64,
        width: 64,
      },
      {
        accessor: "type5",
        minWidth: 64,
        width: 64,
      },
      {
        accessor: "unitype",
        minWidth: 74,
        width: 74,
      },
    ],
  },
  {
    id: "feedback",
    headerTitle: "feedback",
    Footer: () => null,
    columns: [
      { accessor: "dt_send", minWidth: 104, width: 104, sortType: "datetime" },
      { accessor: "dt_get", minWidth: 104, width: 104, sortType: "datetime" },
      { accessor: "phase_a_cl", minWidth: 90, width: 90 },
      { accessor: "phase_a_pu", minWidth: 68, width: 68 },
      { accessor: "phase_b_cl", minWidth: 90, width: 90 },
      { accessor: "phase_b_pu", minWidth: 68, width: 68 },
      { accessor: "phase_c_cl", minWidth: 90, width: 90 },
      { accessor: "phase_c_pu", minWidth: 68, width: 68 },
      { accessor: "result_verify", minWidth: 146, width: 146 },
      { accessor: "comment_verify", minWidth: 200, width: 200 },
      { accessor: "dt_change", minWidth: 100, width: 100, sortType: "datetime" },
      { accessor: "sn_change", minWidth: 96, width: 96 },
      { accessor: "type_hack", minWidth: 100, width: 100 },
    ],
  },
  {
    sticky: "right",
    id: "fav",
    Header: null,
    Footer: () => null,
    columns: [
      {
        accessor: "fav",
        minWidth: 40,
        width: 40,
        sortType: () => undefined,
      },
    ],
  },
];
