import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { CusTypo } from "components/cusTypo/custom-typography";

export const tool_base = (theme) => css`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid #d7dbe3;
  border-radius: 6px;
  background-color: ${theme.mtsColor.background.secondary.lightMode};
  cursor: pointer;
`;

export const SFltBtn = styled.div<{ withValue?: boolean }>`
  ${(props) => tool_base(props.theme)};

  & > .MuiSvgIcon-root path {
    stroke: ${(props) => props.theme.mtsColor.text.secondary.darkMode};
  }

  ${(props) =>
    props.withValue &&
    `
		background-color: ${props.theme.mtsColor.background.secondary.darkMode};
		border-color: ${props.theme.mtsColor.background.secondary.darkMode};

		& > .MuiSvgIcon-root path {
			stroke: white;

		}
  `};
`;

export const SFltVivid = styled.div`
  ${(props) => tool_base(props.theme)};
  background: white;
`;

export const STxtGrey = styled(CusTypo)`
  display: block;
  color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
`;

export const STxtLink = styled(CusTypo)`
  display: block;
  color: ${(props) => props.theme.mtsColor.text.primaryLink.lightMode};
  cursor: pointer;
`;

export const SList = styled.div`
  overflow: scroll;
`;

export const SRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;

  & .MuiSvgIcon-root {
    fill: ${(props) => props.theme.mtsColor.text.tertiary.lightMode};
    cursor: pointer;
  }
`;

export const SVar = styled.div`
  padding: 12px;
  border-radius: 8px;
  text-align: left;
  background-color: ${(props) => props.theme.mtsColor.background.secondary.lightMode};
`;

export const SGroup = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  & > div {
    margin-left: auto;
    min-height: 0;
    flex: 0 0 auto;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 8px;
  }
`;

export const SStack = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  text-align: center;
`;

export const SButtons = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  gap: 12px;

  & button {
    width: 100%;
  }
`;

export const SControls = styled.div`
  min-width: 0;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  gap: 12px;
`;
