/* eslint-disable eqeqeq */
import React, { useCallback, useEffect, useLayoutEffect, useState, memo, useRef } from "react";
import { TableVirtuoso } from "react-virtuoso";
// NOTE заменил node-pkg на копи-пасту кода и поправил в нём крашащее условие
import { useSticky } from "./react-table-sticky";
import { isEmpty, toString } from "lodash";
import {
  Column,
  SortingRule,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
  useBlockLayout,
  useRowSelect,
  Row,
} from "react-table";

import { CusTypo } from "components/cusTypo/custom-typography";
import { NotifyBlock } from "components/notification-block/notification-block";
import { STable } from "./styles";
import { default_header_height } from "./utils";

export const magic_offset = 13;
const keycode_arrow_down = 40;
const keycode_arrow_up = 38;

export interface ITableFilterClm {
  column: string;
  value: any;
}

export interface ITableSortColumn {
  id: string; //accessor
  desc: boolean;
}

interface IProp {
  data: {
    [key: string]: any;
  }[];
  columns: Column<any>[];
  sortBy?: SortingRule<object>[]; // дефолтная сортировка на маунт
  initSelRow?: Record<string, boolean>; // пока что предполагается что 1 элемент в объекте передаем
  onRowClick?: (clickData: any) => void;
  view?: undefined | "tabs" | "expandable" | "alternatingRows" | "sheets";
  /* filterByColumn - фильтр для >= колонок. Нужно задать корректную обработку value из этого пропса (если нужен фильтр по мульти значениям одной колонки),
  для этого при объявлении columns конкретной таблицы в соответствующей колонке для параметра 'filter' нужно написать функцию или использовать встроенные варианты
  */
  filterByColumn?: ITableFilterClm[];
  sortByColumn?: SortingRule<ITableSortColumn>[]; // сортируем колонку внешними контролзами
  /*  Если передавать стрингу - фильтрация таблицы через совпадение её с содержимым ЛЮБОЙ колонки.
  В ином случае (пример <ResearchTable/>) в globalFilter можно хранить кастомную структуру, тогда нужно писать globalFilterFunction и фильтровать rows на базе этой структуры
  */
  globalFilter?: string | any;
  globalFilterFunction?: (
    rows: Row<object>[],
    columnIds: string[],
    filterValue: any,
  ) => Row<object>[]; // в связке с globalFilter
  /* onRowsChange срабатывает на изменение количества рядов таблицы (фильтрация и т.д),
	по сравнению с первым маунтом и инициализационными данными
  */
  onRowsChange?: Function;
  height?: number;
  /* incViewportBy - в случае с краскрывающимися элементами иногда случается баг, чо через них невозможно проскролить, из-за внутренних расчетов virtuoso
  в такую таблицу лучше передавать примерную дополнительную высоту раскрытого элемента и проблема уйдет
  */
  incViewportBy?: number;
  onEndReach?: Function;
  headerHeight?: number;
  withFooter?: boolean;
  withStub?: boolean;
  // поиск по accessor 'id' (должен быть прописан в tableStructure и constructTableData) и скролл к нему.
  savedId?: number;
  /*
  Любые данные не в формате строки таблицы (то есть не массив с построчными данными таблицы как data), которые невозможно получить\посчитать в столбцах.
  Глобально доступен в Cell, Footer, Header - находится внутри аргумента table (рядом с row, column)
  */
  globalData?: { [key: string]: any };
  isSortIconLeft?: boolean;
  //   isSortIconLeft -  отображение иконок сортировки слева в заголовках столбцов
}

export const Table: React.FC<IProp> = memo(
  ({
    data,
    columns,
    sortBy,
    onRowClick,
    view,
    filterByColumn,
    globalFilter,
    onRowsChange,
    height,
    initSelRow,
    incViewportBy,
    onEndReach,
    sortByColumn,
    headerHeight = default_header_height,
    withFooter = false,
    withStub = false,
    savedId,
    globalFilterFunction,
    globalData,
    isSortIconLeft,
  }) => {
    const {
      rows,
      setGlobalFilter,
      setAllFilters,
      setSortBy,
      getTableProps,
      getTableBodyProps,
      headerGroups,
      footerGroups,
      prepareRow,
      page,
      toggleRowSelected,
      toggleAllRowsSelected,
      setPageSize,
      state: { selectedRowIds },
    } = useTable(
      {
        columns,
        data,
        initialState: {
          pageIndex: 0,
          pageSize: 100,
          selectedRowIds: initSelRow ?? {},
          sortBy: sortBy ?? [],
        },
        globalData,
        globalFilter: globalFilterFunction,
        autoResetPage: false,
        autoResetSortBy: false,
        autoResetSelectedRows: false,
        autoResetGlobalFilter: false,
        autoResetFilters: false,
      },
      useBlockLayout,
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination,
      useRowSelect,
      useSticky,
    );

    const parentRef = React.useRef<any>();
    const virtRef = React.useRef<any>();

    const handleRowClick = (preparedRow: Row<any>, scrollIndex) => {
      toggleAllRowsSelected(false);
      onRowClick?.(preparedRow.original.id);
      toggleRowSelected(preparedRow.id);
      virtRef.current.scrollToIndex({
        index: scrollIndex,
        align: "center",
        behavior: "auto",
      });
    };

    const handleRowChange = useCallback((a) => {
      onRowsChange?.(a);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [tableHeight, setHeight] = useState(500);

    const handleKeyboard = (e) => {
      if (!isEmpty(selectedRowIds)) {
        const currInnerSelected = +Object.keys(selectedRowIds);
        const index = page.findIndex((a) => +a.id === currInnerSelected);
        if (e.keyCode == keycode_arrow_down) {
          const nextNumber = index + 1;
          if (nextNumber < page.length) {
            const nextRow = page[nextNumber];
            handleRowClick(nextRow, nextNumber);
          }
        } else if (e.keyCode == keycode_arrow_up) {
          const previousNumber = index - 1;
          if (previousNumber >= 0) {
            const previousRow = page[previousNumber < 0 ? 0 : previousNumber];
            handleRowClick(previousRow, previousNumber);
          }
        }
      }
    };

    const isFirstDraw = useRef<boolean>(true);

    useLayoutEffect(() => {
      if (window && parentRef) {
        const topOffset = parentRef?.current?.getBoundingClientRect().top ?? 0;
        setHeight(+(window.innerHeight - topOffset - magic_offset).toFixed());
      }
    }, []);

    useEffect(() => {
      if (data.length > 0) setPageSize(data?.length);
    }, [data.length, setPageSize]);

    useEffect(() => {
      if (filterByColumn) {
        toggleAllRowsSelected(false);
        const newArr = filterByColumn
          ?.reduce((sum, cur) => {
            if (!isEmpty(cur)) sum.push(cur);
            return sum;
          }, [] as any)
          .map((a) => ({ id: a.column, value: a.value }));
        setAllFilters(newArr);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(filterByColumn), setAllFilters]);

    useEffect(() => {
      if (sortByColumn) {
        setSortBy(sortByColumn);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(sortByColumn), setSortBy]);

    useEffect(() => {
      handleRowChange?.(rows.map((a) => a.original));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toString(rows), handleRowChange]);

    useEffect(() => {
      setGlobalFilter(globalFilter);
    }, [globalFilter, setGlobalFilter]);

    useEffect(() => {
      if (!isEmpty(page) && savedId && isFirstDraw.current) {
        const trg = page.find((a) => a.values.id === savedId);
        if (trg != null && !isEmpty(trg)) handleRowClick(trg, trg.index);
        isFirstDraw.current = false;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toString(page), savedId]);

    return (
      <STable
        height={height ?? tableHeight}
        ref={parentRef}
        onKeyDown={handleKeyboard}
        headerHeight={headerHeight}
        withStub={withStub}
        className="sticky"
        isSortIconLeft={isSortIconLeft}
      >
        {!data.length && withStub ? (
          <NotifyBlock
            content="Ваши приборы учета под защитой. На данный момент аномалии не обнаружены, список анализируется и формируется."
            status="success"
          />
        ) : (
          <TableVirtuoso
            ref={virtRef}
            style={{ height: height ?? tableHeight }}
            totalCount={rows.length}
            increaseViewportBy={incViewportBy ?? 0}
            endReached={onEndReach?.() as any}
            components={{
              Table: ({ ...props }) => <div {...getTableProps()} {...props} className="table" />,
              TableBody: React.forwardRef(({ style, ...props }, ref) =>
                isEmpty(data) || isEmpty(page) ? (
                  <div className="empty">
                    <CusTypo variant="p4_regular">
                      {isEmpty(data)
                        ? "Список пуст"
                        : isEmpty(page)
                        ? "Фильтрация не дала результатов"
                        : ""}
                    </CusTypo>
                  </div>
                ) : (
                  <div
                    className={`tbody ${
                      view === "alternatingRows" ? "tbody--alternating-rows" : ""
                    }`}
                    {...getTableBodyProps()}
                    {...props}
                    ref={ref}
                  />
                ),
              ),
              TableHead:
                headerHeight != undefined
                  ? React.forwardRef(({ style, ...props }, ref) => (
                      <div
                        className={`thead ${
                          ["tabs", "expandable"].includes(view as any) ? "thead--tab" : ""
                        }`}
                        {...props}
                        ref={ref}
                      />
                    ))
                  : () => null,
              TableFoot: withFooter
                ? React.forwardRef(({ style, ...props }, ref) => (
                    <div className="tfoot" {...props} ref={ref} />
                  ))
                : () => null,
              FillerRow: ({ height: h }: any) => <div style={{ height: h }} />,
              TableRow: (props) => {
                const index = props["data-index"];
                const row = rows[index];
                return (
                  <div
                    {...props}
                    {...row.getRowProps()}
                    className={`tbody_tr ${view === "tabs" ? "tbody_tr--tab" : ""} ${
                      view === "expandable" ? "tbody_tr--tab-expandable" : ""
                    } ${view === "alternatingRows" ? "tbody_tr--tab-alternating-rows" : ""} ${
                      view === "sheets" ? "tbody_tr--sheets" : ""
                    }`}
                    onClick={(e) => {
                      handleRowClick(row, index);
                    }}
                  />
                );
              },
            }}
            fixedHeaderContent={() => {
              return headerGroups.map((headerGroup, indx) => (
                <div
                  className={`thead_tr ${view === "sheets" ? "thead_tr--sheets" : ""}`}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column: any) => (
                    <div
                      className={`th  ${headerGroups.length > 1 && indx === 0 ? "th-group" : ""} ${
                        column.totalMaxWidth === 0 || column.isHeaderHidden ? "td--plug" : ""
                      }`}
                      {...column.getHeaderProps({
                        ...column.getSortByToggleProps(),
                        style: {
                          width:
                            view === "sheets"
                              ? (column as any).totalMinWidth
                              : column.originalWidth === "100%"
                              ? column.originalWidth
                              : "auto",
                          minWidth: column.isHeaderHidden ? 0 : column.totalMinWidth,
                        },
                      })}
                      title={undefined}
                    >
                      {column.render("Header")}
                    </div>
                  ))}
                </div>
              ));
            }}
            fixedFooterContent={() => {
              return footerGroups.slice(0, 1).map((group) => (
                <div
                  className={`tfoot_tr ${view === "sheets" ? "tfoot_tr--sheets" : ""}`}
                  {...group.getFooterGroupProps()}
                >
                  {group.headers.map((column: any) => (
                    <div
                      className={`tf ${column.maxWidth === 0 ? "td--plug" : ""}`}
                      {...column.getHeaderProps({
                        style: {
                          width: column.flexWidth || "auto",
                          minWidth: column.totalMinWidth,
                          maxWidth: "fit-content",
                        },
                      })}
                    >
                      {column.render("Footer")}
                    </div>
                  ))}
                </div>
              ));
            }}
            itemContent={(index) => {
              const row = rows[index];
              prepareRow(row);
              return row.cells.map((cell) => {
                return (
                  <div
                    className={`td ${view === "tabs" ? "td--tab" : ""} ${
                      view === "expandable" ? "td--tab-expandable" : ""
                    } ${view === "alternatingRows" ? "td--tab-alternating-rows" : ""} ${
                      view === "sheets" ? "td--sheets" : ""
                    } ${cell.column.maxWidth === 0 ? "td--plug" : ""}
					`}
                    {...cell.getCellProps({
                      style: {
                        width: view === "sheets" ? (cell.column as any).totalMinWidth : "auto",
                        minWidth: (cell.column as any).totalMinWidth,
                        maxWidth: (cell.column as any).totalFlexWidth,
                      },
                    })}
                    title={undefined}
                  >
                    {cell.column.maxWidth !== 0 ? cell.render("Cell") : null}
                  </div>
                );
              });
            }}
          />
        )}
      </STable>
    );
  },
);
