import { useContext, useEffect, useMemo, useState } from "react";
import { isEmpty } from "lodash";

import { Breadcrumbs } from "components/breadcrumbs/breadcrumbs";
import { MTSInput } from "components/mts-input";
import { ResearchTable } from "components/analytics-research/researchTable/researchTable.component";
import {
  useCheckVisibleColumns,
  useReportDashboard,
  useResearchDevice,
  useResearchChart,
} from "hooks/api/analytics";
import { EStorageKeys, StorageAPI } from "utils/localStorageAPI";
import { ContextSettings } from "context/context-settings";
import { useDebounce } from "hooks/useDebounce";
import { Footer } from "components/footer/footer";
import { Spacer } from "components/spacer/spacer.component";
import { getBackendValues } from "components/analytics-research/reserchForm/const";
import { Loader } from "components/loader/loader";
import { Chart } from "components/chart/Chart.component";
import { TableFav } from "components/analytics-research/tableFav/tableFav.component";
import { makeChartData } from "components/chart/utils";
import { ReserchForm } from "components/analytics-research/reserchForm/reserchForm.component";
import { FavsList } from "components/analytics-research/favsList/favsList.component";
import {
  toastEnd,
  toastRepFail,
  toastRepPending,
  toastRepSucc,
} from "components/analytics-research/favsList/subcomponents";
import {
  HeaderInfo,
  InfoAutoScroll,
} from "components/analytics-research/headerInfo/headerInfo.component";
import { PopupActionChildren } from "components/popup-action/popup-action-children";
import { AnalFilter } from "components/analytics-research/analFilter/analFIlter.component";
import { Switch } from "components/switch/switch.component";
import { AnalSettings } from "components/analytics-research/analSettings/analSettings.component";
import { CusTypo } from "components/cusTypo/custom-typography";
import { DataUpdate } from "components/analytics-research/dataUpdate/dataUpdate";
import { AnalArchiveTable } from "components/analytics-research/analArchiveTable/archiveTable.component";
import { SChartWrap } from "components/chart/styles";
import { ANAL_TABS, defaultFavs, IChartMode, IFavs, links, TABLE_RESIZER } from "./const";
import { SSettings, STab, STabItem, STitle, STop, SWrap } from "./styles";

export const AnalyticsResearch = () => {
  const { isDesktop } = useContext(ContextSettings);
  const [selected, setSelected] = useState<number>(0);
  const [showHints, setShowHints] = useState<boolean>(false);
  const [isOpenSettings, setSettings] = useState(false);
  const [searchVal, setSearch] = useState("");
  const [isShowFilter, setShowFilter] = useState(false);
  const [favs, setFavs] = useState<IFavs>(defaultFavs);
  const [appliedFavs, setApplyFavs] = useState<number[] | []>([]);
  const [isFavOpen, setFavOpen] = useState(false);
  const [isFavlist, setFavlist] = useState(false);
  const [dataLength, setDataLength] = useState(undefined);
  const [child, setChild] = useState<string | undefined>(undefined);
  const [tabContent, setTab] = useState("chart");

  const localStatus = StorageAPI.get(EStorageKeys.RESEARCH_FILTER_IS) ?? "off";
  const [isOn, setOn] = useState(localStatus === "on");

  const storageSettings =
    (StorageAPI.get(EStorageKeys.RESEARCH_SETTINGS) &&
      JSON.parse(StorageAPI.get(EStorageKeys.RESEARCH_SETTINGS))) ||
    [];

  const [combinedState, setCombined] = useState<IChartMode & { search: string; provider: string }>({
    max: storageSettings["chart"]?.max ?? 10,
    mult: storageSettings["chart"]?.mult ?? 3,
    mode: "optimize",
    search: storageSettings["search"] ?? "geo",
    provider: storageSettings["provider"] ?? "yandex",
  });

  const { data: visibility } = useCheckVisibleColumns();

  const {
    data,
    fetchNextPage,
    isFetching: researchIsLoading,
    isFetchingNextPage,
    hasNextPage,
    isError,
    error,
  } = useResearchDevice(isOn);

  const researchDevices = data?.pages.flatMap((p) => p.results);

  const [composedGlobData] =
    data?.pages
      .flatMap((p) => ({
        date_norm: p.date_norm,
        date_scores: p.date_scores,
        offset: p.offset,
        total: p.page_cnt * p.limit,
        count: p.count,
      }))
      .slice(-1) || [];

  const { mutate: exportXlsx, isLoading: isRepLoading } = useReportDashboard();

  const debouncedSearch = useDebounce(searchVal, 500);
  const chart = useResearchChart({
    cnt_max_row: combinedState.max,
    id_pp: selected,
    mode: combinedState.mode,
    multiples: combinedState.mult,
  });

  const chartData = makeChartData(chart.data ?? []);

  const handleInput = (e) => {
    setSearch(e.target.value);
  };

  const getDataLength = (a) => setDataLength(a.length);

  const chartDefaults = useMemo(
    () => getBackendValues(researchDevices, selected),
    [researchDevices, selected],
  );

  const handleApplyFavs = () => {
    setApplyFavs(favs.favs);
  };

  const handleClearFavs = () => {
    setApplyFavs([]);
  };
  const isNothingToShow = isError && error?.description !== null;

  useEffect(() => {
    if (isRepLoading) toastRepPending();
  }, [isRepLoading]);

  const forceScroll = async () => {
    let isHasNext = hasNextPage;
    while (isHasNext) {
      try {
        let res = await fetchNextPage();
        isHasNext = res.hasNextPage ?? false;
      } catch {
        toastRepFail();
      }
    }
    toastEnd();
  };

  return (
    <>
      <SWrap isShowResizer={!isNothingToShow}>
        <Breadcrumbs links={links} />
        <STop>
          <STitle>
            <CusTypo variant={isDesktop() ? "h2_medium" : "h4_medium"} font="wide">
              Список устройств
            </CusTypo>
            <HeaderInfo dates={composedGlobData} favs={favs} isShowFavs={!isEmpty(appliedFavs)} />
          </STitle>
          {isNothingToShow ? null : (
            <>
              <SSettings>
                <div className="searchField">
                  <MTSInput
                    searchIcon
                    placeholder="ID, РЭС, Адрес"
                    onChange={handleInput}
                    value={searchVal}
                    size="S"
                  />
                </div>
                <div>
                  <AnalFilter
                    open={() => setShowFilter(true)}
                    close={() => setShowFilter(false)}
                    isOpen={isShowFilter}
                    dataLength={dataLength ?? 0}
                    isFilterOn={isOn}
                    handleOn={setOn}
                  />
                </div>
                <div>
                  <TableFav
                    isOpen={isFavOpen}
                    close={() => setFavOpen(false)}
                    open={() => setFavOpen(true)}
                    favState={favs}
                    setItems={setFavs}
                    onApply={handleApplyFavs}
                    onClear={handleClearFavs}
                    openAllFavs={() => setFavlist(true)}
                    hasValue={!isEmpty(appliedFavs)}
                  />
                </div>
                <div>
                  <PopupActionChildren
                    items={[
                      { title: "Настройки", onClick: () => setSettings(true) },
                      {
                        title: "Обновить данные",
                        onClick: () => setChild("update"),
                      },
                      {
                        title: "Экспорт в Excel",
                        onClick: () =>
                          exportXlsx(undefined, {
                            onSuccess: () => {
                              toastRepSucc();
                            },
                            onError: () => {
                              toastRepFail();
                            },
                          }),
                      },
                      {
                        title: (
                          <Switch
                            id="switch_settings"
                            setChecked={() => undefined}
                            checked={showHints}
                            label="Режим подсказок"
                          />
                        ),
                        onClick: () => setShowHints(!showHints),
                      },
                    ]}
                    onClose={() => setChild(undefined)}
                  >
                    {child ? <DataUpdate isFilterOn={isOn} /> : null}
                  </PopupActionChildren>
                  <AnalSettings
                    isOpen={isOpenSettings}
                    close={() => setSettings(false)}
                    outerState={combinedState}
                    handleOuter={setCombined}
                  />
                </div>
                <FavsList
                  isOpen={isFavlist}
                  close={() => setFavlist(false)}
                  open={() => setFavlist(true)}
                  setFavs={setFavs}
                />
              </SSettings>
            </>
          )}
        </STop>
        {isNothingToShow ? null : (
          <InfoAutoScroll
            cur={data?.pages.flatMap((p) => p.offset)?.slice(-1)?.[0] ?? 0}
            total={data?.pages.flatMap((p) => p.count)?.slice(-1)?.[0] ?? 0}
            handleClick={forceScroll}
            isLoading={isFetchingNextPage}
            isEndReached={!hasNextPage}
          />
        )}
        <ResearchTable
          rawData={researchDevices ?? []}
          globalFilter={{
            arr: appliedFavs,
            text: debouncedSearch,
          }}
          onRowClick={setSelected}
          rowSelected={selected}
          showHints={showHints}
          columnVisibility={visibility ?? {}}
          onRowsChange={getDataLength}
          onEndReach={fetchNextPage}
          setFavs={setFavs}
          favs={favs.favs}
          withStub={isNothingToShow}
          globalData={composedGlobData}
          isSortIconLeft
        />
        {isNothingToShow ? null : (
          <>
            <Spacer value={TABLE_RESIZER} />
            <SChartWrap>
              <STab>
                {ANAL_TABS.map((c) => (
                  <STabItem
                    key={c.value}
                    variant="p4_regular"
                    onClick={() => setTab(c.value)}
                    isActive={tabContent === c.value}
                  >
                    {c.title}
                  </STabItem>
                ))}
              </STab>
              {tabContent === "chart" ? (
                <>
                  <Spacer value="24px" />
                  <Chart id={selected} chartData={chartData as any} scaleX="years">
                    <ReserchForm
                      id={selected}
                      defaultValues={chartDefaults}
                      outerState={combinedState}
                      handleOuter={setCombined}
                      isFilterOn={isOn}
                    />
                  </Chart>
                </>
              ) : (
                <AnalArchiveTable id={selected} />
              )}
            </SChartWrap>
          </>
        )}
      </SWrap>
      <Footer />
      {researchIsLoading ? <Loader isFullPage /> : null}
    </>
  );
};
