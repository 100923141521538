import { FC, useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";

import {
  useResearchFindOnMap,
  useUpdateAnalStatus,
  useUpdateAnalTicket,
} from "hooks/api/analytics";
import { CheckBtn } from "components/check-btn/check-btn.component";
import { CustomWidthTooltip } from "components/tooltip/tooltip";
import { PopupActionChildren } from "components/popup-action/popup-action-children";
import { Select } from "components/select/select";
import { MTSModal } from "components/mts-modal/mts-modal";
import { SFltBtn } from "components/table/table-filter/styles";
import Icon from "components/icons";
import { CommentChange, Optimiser, toastErr, toastSucc } from "./subcomponents";
import { statusFraud, toolStyle, toolTextOptim, toolTextSource } from "./const";
import { ROUTE_RESEARCH_PAGE } from "stream-constants/route-constants";
import { IResearchGeoReq } from "interface/analytics";
import { SBtnMap, SMarksForm, SSpec } from "./styles";

interface IProp {
  id: number;
  defaultValues: any;
  outerState: any;
  handleOuter: Function;
  isFilterOn: boolean;
  geoData?: IResearchGeoReq;
}
export const ReserchForm: FC<IProp> = ({
  id,
  defaultValues,
  outerState,
  handleOuter,
  isFilterOn,
  geoData = undefined,
}) => {
  const chartMode = outerState.mode;
  const [child, setChild] = useState<string | undefined>(undefined);
  const [optim, setOptim] = useState<any>({ max: outerState.max, mult: outerState.mult });
  const [isOpen, setOpen] = useState(false);

  const { mutate: updTicket, isLoading } = useUpdateAnalTicket(isFilterOn, geoData);
  const { mutate: updStatus, isLoading: isStatLoading } = useUpdateAnalStatus(isFilterOn, geoData);
  const { mutate } = useResearchFindOnMap();

  const formInstance = useForm<any>({
    defaultValues,
    mode: "onChange",
  });

  const submitTicket = (event, key) => {
    updTicket(
      {
        id_pp: id,
        action: event ? "ins" : "del",
        ticket: key,
      },
      {
        onSuccess: () => {
          toastSucc();
        },
        onError: () => {
          formInstance.reset(defaultValues);
          toastErr();
        },
      },
    );
  };

  const submitStatus = (fraud, com = formInstance.getValues("comment")) => {
    updStatus(
      {
        id_pp: Number(id),
        fraud_probability: fraud,
        comment: com,
      },
      {
        onSuccess: () => {
          toastSucc();
          setOpen(false);
        },
        onError: () => {
          formInstance.reset(defaultValues);
          toastErr();
        },
      },
    );
  };

  const handleClose = () => {
    formInstance.reset(defaultValues);
    setOpen(false);
  };

  const selectMap = () =>
    mutate(
      {
        id_pp: Number(id),
        search_type: outerState.search?.toUpperCase(),
        selected_map: outerState.provider?.toUpperCase(),
      },
      { onSuccess: (response) => window.open(response.url, "_blank") as any },
    );

  const optimizeLocally = () => handleOuter((s) => ({ ...s, max: optim.max, mult: optim.mult }));

  useEffect(() => {
    formInstance.reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInstance.reset, JSON.stringify(defaultValues)]);

  useEffect(() => {
    setOptim({ max: outerState.max, mult: outerState.mult });
  }, [outerState]);

  return (
    <SMarksForm>
      <Controller
        control={formInstance.control}
        name="t5"
        render={({ field: { onChange, value } }) => {
          return (
            <CheckBtn
              name="t5"
              onChange={(e) => {
                onChange(e);
                submitTicket(e, "t5");
              }}
              value={value}
              isLoading={isLoading}
            >
              Перепроверка
            </CheckBtn>
          );
        }}
      />
      <Controller
        control={formInstance.control}
        name="t2"
        render={({ field: { onChange, value } }) => {
          return (
            <CheckBtn
              name="t2"
              onChange={(e) => {
                onChange(e);
                submitTicket(e, "t2");
              }}
              value={value}
              isLoading={isLoading}
            >
              Майнер
            </CheckBtn>
          );
        }}
      />
      <Controller
        control={formInstance.control}
        name="t3"
        render={({ field: { onChange, value } }) => {
          return (
            <CheckBtn
              name="t3"
              onChange={(e) => {
                onChange(e);
                submitTicket(e, "t3");
              }}
              value={value}
              isLoading={isLoading}
            >
              Снижение
            </CheckBtn>
          );
        }}
      />
      <Controller
        control={formInstance.control}
        name="fp"
        render={({ field: { onChange, value } }) => {
          return (
            <>
              <Select
                initValue={value}
                size="S"
                type="fraude"
                optionsList={statusFraud}
                onChange={(e) => {
                  onChange(e);
                  submitStatus(e);
                }}
                isLoading={isStatLoading}
                placeholder="Без вероятности"
              />
            </>
          );
        }}
      />
      <MTSModal open={isOpen} close={handleClose} size="S">
        <FormProvider {...formInstance}>
          <CommentChange id={id} onClose={handleClose} handleSubmit={submitStatus} />
        </FormProvider>
      </MTSModal>
      <SSpec>
        <SBtnMap onClick={selectMap}>
          <Icon.GeoPositionMts />
        </SBtnMap>
        <CustomWidthTooltip
          title={
            <div style={toolStyle}>{chartMode === "optimize" ? toolTextSource : toolTextOptim}</div>
          }
          arrow
          placement="right"
        >
          <SFltBtn
            withValue={chartMode === "optimize"}
            onClick={() =>
              chartMode === "optimize"
                ? handleOuter((s) => ({ ...s, mode: "source" }))
                : handleOuter((s) => ({ ...s, mode: "optimize" }))
            }
          >
            <Icon.Data />
          </SFltBtn>
        </CustomWidthTooltip>
        <PopupActionChildren
          items={[
            {
              title: "Оптимизация графика",
              onClick: () => setChild("optim"),
            },
            {
              title: "Поиск в радиусе",
              onClick: () => window.open(`/${ROUTE_RESEARCH_PAGE}/${id}`, "_blank", "noreferrer"),
            },
            {
              title: "Оставить комментарий",
              onClick: () => setOpen(true),
            },
          ]}
          onClose={() => setChild(undefined)}
        >
          {child && child === "optim" ? (
            <Optimiser
              handleOuter={setOptim}
              outerState={optim}
              isWidget
              handleWidgetBtn={optimizeLocally}
            />
          ) : null}
        </PopupActionChildren>
      </SSpec>
    </SMarksForm>
  );
};
