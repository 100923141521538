import { format, getYear } from "date-fns";
import { isEmpty } from "lodash";

import { RenderFilter } from "components/table/render-filter/renderFilter.component";
import { IResearchFavslistRes } from "interface/analytics";
import { NameLoadListForm, STextCut, SelectAction } from "./subcomponents";
import { CusTypo } from "components/cusTypo/custom-typography";
import { CustomWidthTooltip } from "components/tooltip/tooltip";

export const handleDateSwitch = (setter, trgCol, trgVal) => {
  setter((s) => {
    const copy = [...s];
    const trg = s.find((a) => a.column === trgCol);
    const ind = trg == null ? -1 : s.indexOf(trg);

    if (isEmpty(trg?.value)) {
      copy[ind] = { column: trgCol, value: [trgVal] };
    } else {
      copy[ind] = { column: trgCol, value: [] };
    }
    return copy;
  });
};

function displayCurrYear(rows, columnIds, filterValue) {
  if (filterValue.length === 0) return rows;
  else {
    const [currentYear] = filterValue;

    return rows.filter((row) => {
      return getYear(new Date(row.original.date_change)) === currentYear;
    });
  }
}

export const constructTableData = (draft: IResearchFavslistRes[]) =>
  draft?.map((d) => {
    const { id_list, date_change } = d;

    return {
      ...d,
      id: id_list,
      date_change: new Date(date_change ?? 0),
    };
  });

export const tableStructure = [
  {
    accessor: "name_list",
    minWidth: 128,
    maxWidth: 128,
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Название"
        withPadding
      />
    ),
  },
  {
    accessor: "comment_",
    minWidth: 154,
    maxWidth: 154,
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Комментарий"
        withPadding
      />
    ),
  },
  {
    accessor: "date_change",
    minWidth: 92,
    maxWidth: 92,
    sortType: "datetime",
    canFilter: true,
    filter: displayCurrYear,
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Дата"
        withPadding
      />
    ),
  },
  {
    accessor: "user_change",
    minWidth: 150,
    maxWidth: 150,
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Пользователь"
        withPadding
      />
    ),
  },
  {
    accessor: "user_id",
    minWidth: 0,
    maxWidth: 0,
    canFilter: true,
    filter: "arrIncludesSome",
  },
  {
    accessor: "action",
    minWidth: 80,
    maxWidth: 94,
    disableSortBy: true,
  },
];

export const constructColumns = ({ columns, setFavs, closeSlide }) => {
  return columns.map((singleColumn) => {
    return {
      ...singleColumn,
      ...(singleColumn.accessor === "name_list"
        ? {
            Cell: ({ row }) => (
              <>
                <NameLoadListForm
                  id={row.original.id_list}
                  author={row.original.user_id}
                  comment={row.original.comment_}
                  title={row.original.name_list}
                  setFavs={setFavs}
                  closeSlide={closeSlide}
                />
              </>
            ),
          }
        : singleColumn.accessor === "comment_"
        ? {
            Cell: ({ value }) =>
              value.length > 20 ? (
                <CustomWidthTooltip title={value} arrow placement="right">
                  <span
                    style={{
                      width: "100%",
                      overflowX: "hidden",
                      overflowY: "inherit",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <STextCut variant="c1_regular">{value ?? ""}</STextCut>
                  </span>
                </CustomWidthTooltip>
              ) : (
                <STextCut variant="c1_regular">{value ?? ""}</STextCut>
              ),
          }
        : singleColumn.accessor === "date_change"
        ? {
            Cell: ({ value }) => (
              <CusTypo variant="c1_regular"> {format(new Date(value ?? 0), "dd.MM.yyyy")}</CusTypo>
            ),
          }
        : singleColumn.accessor === "action"
        ? {
            Cell: ({ row }) => (
              <SelectAction
                id={row.original.id_list}
                author={row.original.user_id}
                comment={row.original.comment_}
                title={row.original.name_list}
                setFavs={setFavs}
                closeSlide={closeSlide}
              />
            ),
          }
        : {
            Cell: ({ value }) => <STextCut variant="c1_regular">{value ?? ""}</STextCut>,
          }),
    };
  });
};

const titleByAction = {
  load: "Загрузить",
  edit: "Редактировать имя",
  copy: "Дублировать",
  del: "Удалить",
  xls: "Экспорт в Excel",
};

const availActions = [
  {
    name: "load",
    isRestricted: false,
  },
  {
    name: "edit",
    isRestricted: true,
  },
  {
    name: "copy",
    isRestricted: false,
  },
  {
    name: "del",
    isRestricted: true,
  },
  {
    name: "xls",
    isRestricted: false,
  },
];

export const makeSet = ({ load, edit, copy, del, flag, xls }) => {
  const funcObj = { load, edit, copy, del, xls };
  return availActions
    .map((a) => {
      if (a.isRestricted) {
        return flag ? a.name : undefined;
      } else {
        return a.name;
      }
    })
    .filter((b) => b != null)
    .map((c) => ({
      title: titleByAction[c!],
      onClick: funcObj[c!],
    }));
};
